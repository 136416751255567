<template>
  <div class="filter-seller">
    <p>Seller:</p>
    <div>
      <b v-if="!loaded">Carregando...</b>
      <multiselect
        v-if="loaded && sellersList.length > 0"
        class="filter-seller__select"
        placeholder="Digite aqui para filtrar"
        noOptions="Sem resultados"
        label="name"
        track-by="id"
        v-model="selected"
        :options="sellersList"
        :searchable="true"
        :allow-empty="true"
        :show-labels="false"
        :hide-selected="selectMultiple"
        :multiple="selectMultiple"
        :close-on-select="!selectMultiple"
        @remove="setSelected"
        @input="setSelected"
      ></multiselect>

      <b-button v-if="selected !== null" variant="outline-dark" size="sm" @click="clearFilter">
        limpar
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getSellersList } from "@/api/sellers";
export default {
  data() {
    return {
      selected: null,
      loaded: false,
    };
  },
  props: ["selectMultiple"],
  mounted() {
    this.getSellersList();
  },
  computed: {
    ...mapState({
      sellersList: (state) => state.sellers.sellersList,
    }),
  },
  methods: {
    getSellersList,
    setSelected() {
      if (this.selectMultiple === true) {
        if (this.selected.length > 0) {
          var ids = this.selected.map(function(item) {
            return item.sellerId;
          });
          this.$emit("changeSeller", ids.join(","));
        } else {
          this.$emit("changeSeller", null);
        }
      } else {
        this.$emit("changeSeller", this.selected.sellerId);
      }
    },
    clearFilter() {
      this.selected = null;
      this.$emit("changeSeller", null);
    },
  },
  watch: {
    sellersList() {
      this.loaded = true;
    },
  },
};
</script>

<style lang="scss">
.filter-seller {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  &__select {
    min-width: 200px;
    margin: 0 5px;
  }
  > div {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: 40px;
    .btn {
      height: 30px;
    }
  }
}
</style>

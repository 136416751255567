export function formataCPF(cpf) {
  //retira os caracteres indesejados...
  cpf = cpf.replace(/[^\d]/g, "");
  //realizar a formatação...
  return cpf ? cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : "";
}

export function limpaCPF(cpf) {
  //retira os caracteres indesejados...
  return cpf.replace(/[^\d]/g, "");
}

export function formataCEP(cep) {
  //retira os caracteres indesejados...
  cep = cep.replace(/[^\d]/g, "");
  //realizar a formatação...
  return cep ? cep.replace(/(\d{5})(\d{3})/, "$1-$2") : "";
}


export function percentage(value, showSymbol = true) {
  // Multiplica por 100 para obter a porcentagem, arredonda e divide por 100 novamente
  // var roundedValue = (value * 100).toFixed(decimalPlaces) / 100;
  var decimalPlaces = 1;
  var roundedValue = value.toFixed(decimalPlaces);
  if ((showSymbol)) {
    var symbol = (value < 0) ? '▼' : '▲';
    return `${symbol} ${roundedValue.replace('-', '')}%`;
  } else {
    return `${roundedValue}%`;
  }
}